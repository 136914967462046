<template>
    <div>
        <div class="mobile-bottom-box">
            <div class="texts">
                <div>咨询电话</div>
                <div class="m-t-15">0431-83444444 / 15764363945</div>
            </div>
            <div class="d-flex f-12">
                <div class="m-r-20">
                    <img src="@/assets/image/code-1.jpg" class="w-80 h-80">
                    <div class="align-center m-t-5">集团公众号</div>
                </div>
                <div>
                    <img src="@/assets/image/code-3.jpg" class="w-80 h-80">
                    <div class="align-center m-t-5">联系人微信</div>
                </div>
            </div>
        </div>
    </div>
</template>
