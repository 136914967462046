<template>
    <div>
        <div class="tel-header">
            <div class="d-flex d-flex-items-center w-55p tel-header-img-box">
                <img src="@/assets/image/logo.png" class="display-block w-45p">
            </div>
            <div class="d-flex d-flex-items-center w-55p tel-header-img-box d-flex-end" @click="nacClick">
                <img src="@/assets/image/more-icon.png" class="display-block w-10p">
            </div>
        </div>
        <div class="navs" v-if="navShow">
            <div class="items" :class="{'nav-active-tel':item.url == active}" v-for="(item,index) in navs" :key="index"  @click="jump(item.url)">
                <div>{{item.name}}</div>
                <div><img src="@/assets/image/icons/right.png" class="w-50p"></div>
            </div>
        </div>
    </div>
</template>

<script lang='ts'>
import { defineComponent,ref } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
    name:'login',
    setup(){
        const navShow = ref(false)
        const nacClick= () =>{
            navShow.value = !navShow.value
        }

        const router = useRouter()
        console.log(useRouter().currentRoute.value.path)
        const active:any = ref(useRouter().currentRoute.value.path)
        const navs:any = ref([
            {
                name:"首页",
                url:"/index"
            },
            {
                name:"企业服务",
                url:"/companyService"
            },
            {
                name:"最新动态",
                url:"/news"
            },
             {
                name:"行业案例",
                url:"/case"
            },
            {
                name:"合作共赢",
                url:"/cooperate"
            },
            {
                name:"关于我们",
                url:"/about"
            },
        ])
        const jump = (url:string) => {
            router.push(url)
        }
        return{
            navShow,nacClick,navs,active,jump
        }
    }
});
</script>
