<template>
    <div class="mobile-content">
        <div class="w-100p position-relative overflow-h">
            <img src="@/assets/image/index/bg.png" class="w-100p">
            <div class="mobile-content-bg">
                <div>企业技术服务提供者</div>
                <div>网络安全/软件开发/广告传媒/财税服务</div>
            </div>
        </div>
        <div class="p-b-200">
            <div class="company-service-items-m">
                <div class="company-service-items-h-m"></div>
                <div class="company-service-items-img-box-m">
                    <div class="company-service-items-img-content-m">
                        <img src="@/assets/image/company/1.png" class="w-60 h-60">
                    </div>
                </div>
                <div class="color-white p-t-15 p-l-20 p-r-20">
                    <div class="f-17">网络安全</div>
                    <div class="f-17 p-t-10">Network security</div>
                    <div class="p-t-60 company-service-items-text-m">渗透测试（Penetration Testing）是由具备高技能和高素质的安全服务人员发起、并模拟常见黑客所使用的攻击手段对目标系统进行模拟入侵。渗透测试服务的目的在于充分挖掘和暴露系统的弱点，从而让管理人员了解其系统所面临的威胁。渗透测试工作往往作为风险评估的一个重要环节，为风险评估提供重要的原始参考数据。渗透测试（Penetration Testing）是由具备高技能和高素质的安全服务人员发起、并模拟常见黑客所使用的攻击手段对目标系统进行模拟入侵。渗透测试服务的目的在于充分挖掘和暴露系统的弱点，从而让管理人员了解其系统所面临的威胁。渗透测试工作往往作为风险评估的一个重要环节，为风险评估提供重要的原始参考数据。</div>
                </div>
            </div>
            <div class="company-service-items-m">
                <div class="company-service-items-h-m"></div>
                <div class="company-service-items-img-box-m">
                    <div class="company-service-items-img-content-m">
                        <img src="@/assets/image/company/2.png" class="w-60 h-60">
                    </div>
                </div>
                <div class="color-white p-t-15 p-l-20 p-r-20">
                    <div class="f-17">软件开发</div>
                    <div class="f-17 p-t-10">Code audit</div>
                    <div class="p-t-60 company-service-items-text-m">量身定制开发：可根据客户的需求，量身定制一系列符合客户实际应用的软件。 目前的多数信息管理软件可以满足行业内的通用需求,而在特殊流程和客户定制.上成本很高。 对于中小企业来说,可以根据自己的实际业务需求,定制开发一-套适合自己的软件。 开发不求一步到位，一步-步进行完善。如此，可以减少投入,并非常适合自身业务发展。 软件定制主要是指企业管理软件的定制,企业管理软件是指能够体现企业管理的大部分职能(包括决策、计划、组织、领导、 监控、分析等等) ,能够提供实时、相关、准确、完整的数据,为管理者提供决策依据的一种软件。以模块划分,企业管理软件可分为财务管理进销存管理(ERP)、人力资源管理(HR)、供应链管理( SCM)、客户关系管理( CRM )等品种。

</div>
                </div>
            </div>
            <div class="company-service-items-m">
                <div class="company-service-items-h-m"></div>
                <div class="company-service-items-img-box-m">
                    <div class="company-service-items-img-content-m">
                        <img src="@/assets/image/company/3.png" class="w-60 h-60">
                    </div>
                </div>
                <div class="color-white p-t-15 p-l-20 p-r-20">
                    <div class="f-17">财税服务</div>
                    <div class="f-17 p-t-10">Fiscal and tax services</div>
                    <div class="p-t-60 company-service-items-text-m">工商服务：为便捷企业，孵化器提供一站式工商服务，快捷、低成本！竭诚办理：1、工商注册：公司名称核准、提供集中办公区注册地址、起草公司章程、领取营业执照、公章登记备案办理、办理税务登记证、银行开户许可证等。 2、公司变更，注销，转让服务 3、资质代办：食品流通/经营许可证，卫生许可证、科技企业认定、高新企业认定等许可证办理业务
税务服务、资质许可、知识产权
代理记账内容：基础服务（每月整理原始票据、建账、财务核算、出具财务报表、每月纳税申报、日常基础财务，税务咨询、月度装订凭证、年度装订账簿、财税风险预警）赠送服务（年度企业所得税清缴、企业年度工商年检、每月报税提醒、办理季度实时掌握、免费财税咨询和终身售后指导）。
</div>
                </div>
            </div>
            <div class="company-service-items-m">
                <div class="company-service-items-h-m"></div>
                <div class="company-service-items-img-box-m">
                    <div class="company-service-items-img-content-m">
                        <img src="@/assets/image/company/4.png" class="w-60 h-60">
                    </div>
                </div>
                <div class="color-white p-t-15 p-l-20 p-r-20">
                    <div class="f-17">广告传媒</div>
                    <div class="f-17 p-t-10">Advertising Media</div>
                    <div class="p-t-60 company-service-items-text-m">VR拍摄 ：提供高清VR全景拍摄、VR视频拍摄制作、无人机全景航拍、360度全景拍摄制作 logo设计：企业logo设计、标志设计、商标设计、公司logo设计,产品logo设计UI设计：负责软件界面的美术设计、创意工作和制作工作；根据各种相关软件的用户群，提出构思新颖、有高度吸引力的创意设计；对页面进行优化，使用户操作更趋于人性化；维护现有的应用产品。
</div>
                </div>
            </div>
        </div>
    </div>
</template>
