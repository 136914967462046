<template>
    <div>
        <div class="navs">
            <div class="items nav-active-tel">
                <div>首页</div>
                <div><img src="@/assets/image/icons/right.png" class="w-50p"></div>
            </div>
            <div class="items">
                <div>企业服务</div>
                <div><img src="@/assets/image/icons/right.png" class="w-50p"></div>
            </div>
            <div class="items">
                <div>最新动态</div>
                <div><img src="@/assets/image/icons/right.png" class="w-50p"></div>
            </div>
            <div class="items">
                <div>行业案例</div>
                <div><img src="@/assets/image/icons/right.png" class="w-50p"></div>
            </div>
            <div class="items">
                <div>合作共赢</div>
                <div><img src="@/assets/image/icons/right.png" class="w-50p"></div>
            </div>
            <div class="items">
                <div>关于我们</div>
                <div><img src="@/assets/image/icons/right.png" class="w-50p"></div>
            </div>
        </div>
    </div>
</template>
