
import { defineComponent,ref } from 'vue'
import { useRouter } from 'vue-router'
import baseRequest from '@/service/index'
import Message from '@/utils/message'
import {useStore} from 'vuex'
import LocalCache from '@/utils/cache'
import pcTabbar from '@/components/pc/tabbar'
import pageBottom from '@/components/pc/page-bottom'
import pageTelBottom from '@/components/mobile/page-bottom'
import {pcContent, telContent} from './cpns'
import pageHeader from '@/components/mobile/page-header'
import pageNav from '@/components/mobile/page-nav'
export default defineComponent({
    components:{pcTabbar,pcContent,pageTelBottom,pageHeader,pageNav,telContent},
    name:'login',
    setup(){
        return{
        }
    }
});
