
import { defineComponent,ref } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
    setup(){
        const router = useRouter()
        console.log(useRouter().currentRoute.value.path)
        const active:any = ref(useRouter().currentRoute.value.path)
        const navs:any = ref([
            {
                name:"首页",
                url:"/index"
            },
            {
                name:"企业服务",
                url:"/companyService"
            },
            {
                name:"最新动态",
                url:"/news"
            },
             {
                name:"行业案例",
                url:"/case"
            },
            {
                name:"合作共赢",
                url:"/cooperate"
            },
            {
                name:"关于我们",
                url:"/about"
            },
        ])
        const jump = (url:string) => {
            router.push(url)
        }
        return{
            navs,active,jump
        }
    }
});
