<template>
    <div>
        <div class="position-relative w-100p">
            <img src="@/assets/image/index/top.png" class="w-100p">
            <div class="d-flex d-flex-between top-content">
                <div class="top-left-content">
                    <div :class="{'nav-active':item.url == active}" v-for="(item,index) in navs.slice(0,3)" :key="index"
                    @click="jump(item.url)">{{item.name}}</div>
                </div>
                <div class="top-center-content">
                    <img src="@/assets/image/logo.png" class="w-25p">
                </div>
                <div class="top-right-content">
                    <div :class="{'nav-active':item.url == active}" v-for="(item,index) in navs.slice(3,6)" :key="index"
                    @click="jump(item.url)">{{item.name}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang='ts'>
import { defineComponent,ref } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
    setup(){
        const router = useRouter()
        console.log(useRouter().currentRoute.value.path)
        const active:any = ref(useRouter().currentRoute.value.path)
        const navs:any = ref([
            {
                name:"首页",
                url:"/index"
            },
            {
                name:"企业服务",
                url:"/companyService"
            },
            {
                name:"最新动态",
                url:"/news"
            },
             {
                name:"行业案例",
                url:"/case"
            },
            {
                name:"合作共赢",
                url:"/cooperate"
            },
            {
                name:"关于我们",
                url:"/about"
            },
        ])
        const jump = (url:string) => {
            router.push(url)
        }
        return{
            navs,active,jump
        }
    }
});
</script>

<style scoped>
    .tabbar-box{
        position:fixed;
        left:0;
        right:0;
        top:0;
        z-index: 999;
    }
</style>
